<template>
    <div>
        <div class="flex justify-between items-center mb-4">
            <h2 class="text-3xl font-semibold text-black mb-2">My Wallet</h2>

            <div class="flex space-x-2">
                <!-- <button v-if="isVerified" @click="currencyUpdateModal = true"
                    class="flex items-center text-sm space-x-2 text-primary bg-white border rounded-md px-3 py-2 focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                    <SettingsIcon size="20" />
                    <span>Update Currency</span>
                </button> -->

                <button v-if="!isVerified && !loading" @click="verifyOtpModal = true"
                    class="flex items-center text-sm space-x-2 text-white bg-primary border rounded-md px-3 py-2 focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                    <CheckCircleIcon size="20" />
                    <span>Verify Account</span>
                </button>
            </div>
        </div>


        <SectionLoading v-if="loading" text="Wallet data loading..." />

        <div v-else>
            <div v-if="isVerified" class="row">
                <div class="col-md-4">
                    <div class="card siteCard p-3">
                        <div>
                           <div>
                            <p class="text-sm mb-2">Withdrawable Balance</p>
                            <h3 class="text-2xl font-bold text-primary">
                                {{
                                    $formatCurrency(
                                        totalEarnings.withdrawable,
                                        totalEarnings.currency
                                    )
                                }}
                            </h3>
                           </div>

                           <div class="mt-2">
                            <p class="text-sm mb-2">Awaiting Balance</p>
                            <h3 class="text-2xl font-bold text-primary">
                                {{
                                    $formatCurrency(
                                        totalEarnings.escrow,
                                        totalEarnings.currency
                                    )
                                }}
                            </h3>
                           </div>

                            <button type="button" @click="initiateWithdrawal"
                                class="bg-primary text-white py-3 px-4 text-sm hover:bg-primary rounded hover:text-white focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 w-full mt-4">
                                <span class="flex items-center justify-between">
                                    <span> Withdraw </span>
                                    <SendIcon size="16" />
                                </span>
                            </button>
                        </div>
                    </div>

                  
                </div>

                <div class="col-md-8">
                    <!-- <div class="card siteCard p-3" v-if="earningStats.length > 0">
                        <p class="text-uppercase text-sm tracking-wider">Earning Stats</p>
                        <v-chart class="earningsChart" height="500" :option="earningStatChart" />
                    </div> -->

                    <div class="card siteCard p-3">
                        <p class="text-uppercase text-sm tracking-wider mb-3">Withdrawal History</p>

                        <div class="text-xs py-4 px-3 w-full text-center"
                            v-if="!loading && withdrawalHistoryData.length === 0">
                            No data available
                        </div>

                        <div class="border-b pb-2" v-for="(withdrawal, index) in withdrawalHistoryData" :key="index">
                            <p class="text-xs text-gray-400">{{ moment
                                .utc(new Date(withdrawal.created_at))
                                .format("DD MMM YYYY") }}</p>
                            <div>
                                <p class="text-sm font-semibold">Amount: {{ $formatCurrency(
                                    withdrawal.amount,
                                    withdrawal.currency
                                ) }}</p>
                                <p class="text-xs text-gray-600" :class="{
                                    'text-green-500': withdrawal.status === 'completed',
                                    'text-yellow-500': withdrawal.status === 'pending',
                                    'text-red-500': withdrawal.status === 'declined',
                                }">Status: {{ withdrawal.status | capitalize }}</p>
                            </div>
                        </div>
                    </div>

                    <div v-if="false" class="card siteCard p-3">
                        <p class="text-uppercase text-sm tracking-wider mb-3">Earning Listing</p>

                        <div class="overflow-x-auto">
                            <table class="min-w-full bg-white">
                                <thead class="bg-gray-100">
                                    <tr>
                                        <th
                                            class="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Date</th>
                                        <th
                                            class="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Amount</th>
                                        <th
                                            class="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Streams Count</th>
                                        <th
                                            class="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Downloads Count</th>
                                    </tr>
                                </thead>
                                <tbody class="text-gray-700">
                                    <tr v-for="(stat, index) in earningStats" :key="index">
                                        <td class="px-3 py-3 whitespace-nowrap text-sm">{{
                                            moment(stat.name, "YYYY-MM").format("MMMM YYYY")
                                        }}</td>
                                        <td class="px-3 py-3 whitespace-nowrap text-sm">{{ $formatCurrency(
                                            Number(stat.revenue),
                                            'eur'
                                        )
                                        }}  
                                    </td>
                                        <td class="px-3 py-3 whitespace-nowrap text-sm">{{ stat.streams | number("0,0") }}
                                        </td>
                                        <td class="px-3 py-3 whitespace-nowrap text-sm">{{ stat.downloads | number("0,0") }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div v-if="!loading && earningStats.length === 0" class="text-xs py-4 px-3 w-full text-center">
                                No data available
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!isVerified">
                <p class="mb-3">
                    You need to verify your account before you can access your wallet.
                </p>
                <div>
                    <button @click="verifyOtpModal = true" class="text-primary outline-none">Click here to verify your
                        account</button>
                </div>
            </div>

        </div>

        <vs-popup title="Withdrawal" :active.sync="withdrawModal">
            <form @submit.prevent="initiateUpdate()">
                <div class="mb-4">

                    <label class="text-sm block text-gray-700 font-medium mb-1">How much would you like to withdraw
                        ?</label>

                    <input type="text" v-model="withdrawalPayload.amount"
                        class="py-2 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                        @input="validateCurrencyInput" />
                </div>

                <button type="submit" :disabled="withdrawalLoading"
                    class="bg-primary w-full text-white py-3 px-4 hover:bg-primary rounded hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 flex justify-between">
                    <span>Proceed</span>
                    <Loading v-if="withdrawalLoading" size="25px" color="white" />
                </button>
            </form>
        </vs-popup>

        <vs-popup title="Update Currency" :active.sync="currencyUpdateModal">
            <form @submit.prevent="saveCurrencyUpdate()">
                <div class="mb-4">

                    <label class="text-sm block text-gray-700 font-medium mb-1">Select Currency</label>

                    <div class="flex space-x-4 my-3 currency-label">
                        <vs-radio v-model="currencyPayload.currency" vs-name="currency" vs-value="ngn">
                            <span class="pl-1">Naira</span>
                        </vs-radio>

                        <vs-radio v-model="currencyPayload.currency" vs-name="currency" vs-value="usd">
                            <span class="pl-1">Dollar</span>
                        </vs-radio>
                    </div>
                </div>

                <button type="submit" :disabled="currencyLoading"
                    class="bg-primary w-full text-white py-3 px-4 hover:bg-primary rounded hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 relative flex justify-between">
                    <span>Save update</span>
                    <Loading v-if="currencyLoading" size="25px" color="white" />
                </button>
            </form>
        </vs-popup>

        <vs-popup title="Verify OTP" :active.sync="verifyOtpModal">
            <OTPVerify @onSuccess="verifySuccess" @onError="verifyError" :newaccount="newAccount"/>
        </vs-popup>
    </div>
</template>

<script>
import {
    CreditCardIcon,
    SendIcon,
    SettingsIcon,
    CheckCircleIcon
} from "vue-feather-icons";
import SectionLoading from "@/components/SectionLoading.vue";
import Loading from "@/components/Loading.vue";
import OTPVerify from "@/components/OTPVerify.vue";

export default {
    components: {
        CreditCardIcon,
        SectionLoading,
        SendIcon,
        SettingsIcon,
        CheckCircleIcon,
        Loading,
        OTPVerify
    },
    data() {
        return {
            isVerified: false,
            newaccount: false,
            statistics: '',
            loading: false,
            totalEarnings: {},
            withdrawalHistoryData: [],
            earningStats: [],
            currencyUpdateModal: false,
            withdrawModal: false,
            currencyLoading: false,
            withdrawalLoading: false,
            verifyOtpModal: false,
            isWithdrawalUpdate: false,
            otpVerifyLoading: false,
            otp: '',
            withdrawalPayload: {
                amount: ""
            },
            currencyPayload: {
                currency: 'naira'
            },
            earningStatChart: {
            },
        }
    },
    mounted() {
        this.currencyPayload.currency = this.userCurrency || 'ngn'
        this.getPageData()
    },
    computed: {
        user() {
            return this.$store.getters.user
        },
        userCurrency() {
            return this.$store.getters.user?.currency
        }
    },
    methods: {
        getPageData() {
            this.getEarningStats()
            this.getTotalEarnings();
            this.getWithdrawalHistory();
        },
        getTotalEarnings() {
            this.loading = true;

            let fetch = {
                path: `/artist/total-earnings`,
            };
            this.$store
                .dispatch("getRequest", fetch)
                .then((resp) => {
                    this.totalEarnings = resp.data?.data || {};

                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                    if (err.response) {
                        this.$vs.notify({
                            title: "Earnings Data",
                            text: err.response.data.message,
                            color: "warning",
                            icon: "error",
                            position: "bottom-center",
                        });
                    } else {
                        this.$vs.notify({
                            title: "Earnings Data",
                            text: "Unable to get Earnings Data",
                            color: "dark",
                            icon: "error",
                            position: "bottom-center",
                        });
                    }
                });
        },
        getWithdrawalHistory() {
            this.loading = true;

            let fetch = {
                path: `/artist/withdrawal-history`,
            };
            this.$store
                .dispatch("getRequest", fetch)
                .then((resp) => {
                    this.withdrawalHistoryData = resp.data?.data || [];
                    this.isVerified = true
                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                    if (err.response) {
                        if (err.response?.status === 403) {
                            this.isVerified = false
                            this.verifyOtpModal = true
                            this.newAccount = true
                        }

                        this.$vs.notify({
                            title: "Withdrawal History Data",
                            text: err.response.data.message,
                            color: "warning",
                            icon: "error",
                            position: "bottom-center",
                        });
                    } else {
                        this.$vs.notify({
                            title: "Withdrawal History Data",
                            text: "Unable to get Withdrawal History Data",
                            color: "dark",
                            icon: "error",
                            position: "bottom-center",
                        });
                    }
                });
        },
        getEarningStats() {
            this.loading = true;

            let fetch = {
                path: `/artist/earning-stats`,
            };
            this.$store
                .dispatch("getRequest", fetch)
                .then((resp) => {
                    this.earningStats = resp.data?.data || [];
                    this.plotChart()
                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                    if (err.response) {
                        this.$vs.notify({
                            title: "Withdrawal History Data",
                            text: err.response.data.message,
                            color: "warning",
                            icon: "error",
                            position: "bottom-center",
                        });
                    } else {
                        this.$vs.notify({
                            title: "Withdrawal History Data",
                            text: "Unable to get Withdrawal History Data",
                            color: "dark",
                            icon: "error",
                            position: "bottom-center",
                        });
                    }
                });
        },
        plotChart() {
            const reversedEarningStats = this.earningStats.reverse() || []

            const labels = reversedEarningStats.map(d => {
                const dateMoment = this.moment(d.name, "YYYY-MM");
                return dateMoment.format("MMMM YYYY");
            }) || []

            const valueList = reversedEarningStats.map(d => Number(d.revenue).toFixed(2) || 0) || []

            this.earningStatChart = {
                color: ["#f88f80"],
                padding: 0,
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow"
                    }
                },
                xAxis: [
                    {
                        data: labels,
                        type: "category",
                    },
                ],
                yAxis: [
                    { type: "value" },
                ],
                series: [
                    {
                        type: 'line',
                        showSymbol: false,
                        data: valueList
                    }
                ]
            }
        },
        initiateWithdrawal() {
            if (this.user?.bank) {
                this.withdrawModal = true;
            } else {
                this.$toast.info(
                    "Withdrawal",
                    "Please add your bank account details.",
                    this.$toastPosition
                );
                this.$router.push('/update-bank-account');
            }
        },
        sendWithdrawalRequest() {
            if (this.withdrawalPayload.amount === "") {
                this.$toast.info(
                    "Withdrawal",
                    "Please enter the amount you want to withdraw.",
                    this.$toastPosition
                );
                return;
            } else {
                this.withdrawalLoading = true;

                let fetch = {
                    path: `/artist/withdrawal`,
                    data: {
                        amount: this.withdrawalPayload.amount,
                    },
                };

                this.$store
                    .dispatch("postRequest", fetch)
                    .then((resp) => {
                        this.getWithdrawalHistory();

                        this.withdrawModal = false;
                        this.getPageData();
                        this.$toast.success(
                            "Withdrawal Request",
                            "Withdrawal request sent successfully",
                            this.$toastPosition
                        );
                        
                        this.isWithdrawalUpdate = false;
                    })
                    .catch((err) => {

                        if (err.response) {
                            this.$toast.info(
                                "Withdrawal Request",
                                err.response.data.message,
                                this.$toastPosition
                            );
                        } else {
                            this.$toast.info(
                                "Withdrawal Request",
                                "Something went wrong, please try again",
                                this.$toastPosition
                            );
                        }
                    })
                    .finally(() => {
                        this.withdrawalLoading = false;
                    });
            }
        },
        saveCurrencyUpdate() {
            this.currencyLoading = true;

            let fetch = {
                path: `/artist/convert-currency`,
                data: this.currencyPayload
            };

            this.$store
                .dispatch("postRequest", fetch)
                .then((resp) => {
                    this.currencyLoading = false;
                    this.currencyUpdateModal = false;
                    this.getPageData();
                    this.$toast.success(
                        "Currency Update",
                        "Currency updated successfully",
                        this.$toastPosition
                    );
                })
                .catch((err) => {
                    this.currencyLoading = false;
                    if (err.response) {
                        this.$toast.error(
                            "Currency Update",
                            err.response.data.message || "Unable to update currency",
                            this.$toastPosition
                        );
                    } else {
                        this.$toast.error(
                            "Currency Update",
                            "Unable to update currency",
                            this.$toastPosition
                        );
                    }
                });

        },
        validateCurrencyInput() {
            // Remove non-numeric characters (except the dot for decimal point)
            this.withdrawalPayload.amount = this.withdrawalPayload.amount.replace(/[^0-9.]/g, '');

            // Ensure there's only one dot for the decimal point
            const parts = this.withdrawalPayload.amount.split('.');
            if (parts.length > 2) {
                this.withdrawalPayload.amount = parts[0] + '.' + parts.slice(1).join('');
            }
        },
        verifySuccess(){

            

            this.$toast.success(
                "OTP Verify",
                "OTP verified successfully",
                this.$toastPosition
            );

            this.withdrawModal = false;
            this.verifyOtpModal = false;

            if(this.isWithdrawalUpdate){
                this.sendWithdrawalRequest()
            }else {
                this.getPageData();
            }  
        },
        verifyError(err){
            if (err.response) {
                this.$toast.info(
                    "OTP Verify",
                    err.response.data.message,
                    this.$toastPosition
                );
            } else {
                this.$toast.info(
                    "OTP Verify",
                    "Something went wrong, please try again",
                    this.$toastPosition
                );
            }
        },
        initiateUpdate() {
            const data = {
                email: this.user ? this.user.email : '',
            };
            let fetch = {
                path: `auth/account/resend-otp`,
                data,
            };
            this.$store
                .dispatch("postRequest", fetch)
                .then((resp) => {
                })
                .catch((err) => { })

            this.verifyOtpModal = true;
            this.withdrawModal = false;
            this.isWithdrawalUpdate = true;
        },
    }
}
</script>

<style lang="scss">
.earningsChart {
    min-height: 300px;
    width: 100%;

    >div:first-child {
        width: 100% !important;

        >canvas {
            width: 100% !important;
        }
    }
}

.currency-label {
    label {
        padding: 10px 14px;
        border: 1px solid gainsboro;
        border-radius: 9px;
        cursor: pointer;
        width: 100%;
        justify-content: flex-start;
    }
}
</style>